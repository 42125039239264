import React from "react";
import styles from "./index.module.scss";
import rightArrow from "../../../assets/icons/right-arrow.webp";

function Company({ imgSrc, logoSrc, description, direction, link }) {
  return (
    <div
      className={styles["company"]}
      style={{ flexDirection: direction === "right" ? "row-reverse" : "row" }} // Corrected style
    >
      <div className={styles["company-content"]}>
        <div className={styles["content-container"]}>
          <div className={styles["companyLogo"]}>
            <img src={logoSrc} alt="Company Logo" />
          </div>

          <p className={styles["company-description"]}>{description}</p>
        </div>

        { link && (
        <div className={styles["rightArrow"]}>
        <a href={link} target="_blank" rel="noopener noreferrer"> 
          <img src={rightArrow} alt="Right Arrow" />
          </a> 
      </div> 
    )}
      </div>
      <div className={styles["company-image"]}>
        <img src={imgSrc} alt="CompanyImage" />
      </div>
    </div>
  );
}

export default Company;
