import styles from "./index.module.scss";
import hero from "../../../assets/images/hero.webp";
import arrow from "../../../assets/icons/right-arrow.svg";
import ourBrandsIcon from "../../../assets/icons/brands_sub-heading-icon.png";
import ourBrandsLine from "../../../assets/images/brands_line.webp";
import ourBrandsHero from "../../../assets/images/brands-hero.webp";
import waterMark from "../../../assets/images/water-mark.webp"
export default function Hero({data}) {

  const {subHeading, heading, description, sectionImg} = data;
  return (
    <section className={styles["hero"]}>
      <div className={styles["hero-content"]}>
        <div className={styles["sub-heading"]}>
          <img src={ourBrandsIcon} alt="" />
          <h3>{subHeading}</h3>
          <img src={ourBrandsLine} alt="" />
        </div>
        <h1 className={styles["heading"]}>{heading}</h1>
        <h3 className={styles["desc"]}>{description}</h3>
        <img src={waterMark} alt="" className={styles["water-mark"]}/>
      </div>
      <div className={styles["hero-img"]}>
        <img src={sectionImg} alt="" />
      </div>
    </section>
  );
}
