import styles from './index.module.scss';
import arrow from '../../assets/icons/right-arrow-white.svg';
// import landingAbout from '../../assets/images/landing-about.png';
import aboutVideo from '../../assets/videos/video.mp4';
import bgImage from '../../assets/images/water-mark.webp';

export default function About() {
  return (
    <section className={styles['about']}>
      <p className={styles['about-hint']}>about saja</p>
      <h2 className={styles['about-title']}>Born out of a passion for nurturing young minds.</h2>
      <p className={styles['about-desc']}>Saja Education Services is a passionate emerging provider of education solutions, envisioning trusted educational chains across Egypt, the GCC, and MENA. Born out of a passion for nurturing young minds, Saja offers innovative educational approaches that empower children to thrive.</p>
      <button className={styles['about-action']}>
        <span className={styles['about-action-text']}>read more</span>
        <span className={styles['about-action-icon']}>
          <img src={arrow} className={styles['about-action-arrow']}  alt='' />
        </span>
      </button>
      <video autoPlay muted loop className={styles['about-image']}>
        <source src={aboutVideo}  />
      </video>
      <img src={bgImage} className={styles['bg-image']} alt='' />
    </section>
  )
}
