import styles from "./index.module.scss";
import logo from "../../assets/images/Saja_Logo black.svg";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import x from "../../assets/icons/x.svg";
import { HashLink } from 'react-router-hash-link';

export default function Footer() {
  return (
    <>
      <footer className={styles["footer"]}>
        <a href="/"><img src={logo} className={styles["footer-logo"]} alt="" /></a>
        <div className={styles["footer-menus"]}>
          <ul className={styles["footer-menu"]}>
            <h3>About Us</h3>
           
            <li>
              <HashLink smooth to="/about#vision">
                Vision
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/about#mission">
                Mission
              </HashLink>
            </li>

          </ul>
          <ul className={styles["footer-menu"]}>
            <h3>services</h3>
            <li>childhood education</li>
            <li>Curriculum Development</li>
            <li>Recruiting, Training & Staff Development</li>
            <li>Facility Design. setups & Management</li>
          </ul>
          <ul className={styles["footer-menu"]}>
            <h3>news</h3>
            <li>latest news</li>
            <li>events</li>
          </ul>
          <ul className={styles["footer-menu"]}>
            <h3>contact us</h3>
            <li>Cairo</li>
            <li>Dubai</li>
            <li>Riyadh</li>
            <li>
              <a href="tel:00201111971970">(+20) 111 197 1970</a>
            </li>
          </ul>
        </div>
        <div className={styles["footer-social"]}>
          <p className={styles["footer-social-text"]}>
            Saja Education Services
          </p>
          <div className={styles["vertical-rule"]}></div>
          <a
            href="https://www.facebook.com/share/z5qbeWBkJsbuiabA/?mibextid=qi2Omg"
            target="_blank"
            className={styles["footer-social-icon"]}
          >
            <img src={facebook} alt="" />
          </a>
          <a
            href="https://www.instagram.com/saja_education?igsh=MXNzcjRkaXRtcGxzZA=="
            target="_blank"
            className={styles["footer-social-icon"]}
          >
            <img src={instagram} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/company/saja-education/"
            target="_blank"
            className={styles["footer-social-icon"]}
          >
            <img src={linkedin} alt="" />
          </a>
          <a
            href="https://x.com/SajaEduServices?s=08"
            target="_blank"
            className={styles["footer-social-icon"]}
          >
            <img src={x} alt="" />
          </a>
        </div>
      </footer>
      <div className={styles["copyrights"]}>
        <p>All copyrights reserved (C) 2024</p>
        <p>
          made with passion by{" "}
          <a href="https://www.uniparticle.com/" target="_blank">
            uniparticle
          </a>
        </p>
      </div>
    </>
  );
}
