import styles from './index.module.scss';
import hero from '../../assets/images/hero.webp';
import arrow from '../../assets/icons/right-arrow.svg'
export default function Hero() {
  return (
    <section className={styles['hero']}>
      <h2 className={styles['hero-title']}>Growing Together, Learning Forever</h2>
      <div className={styles['hero-content']}>
        <img src={hero} className={styles['hero-content-image']} />
        <div className={styles['hero-content-section-two']}>
          <div>
            <div>
              <p>
                Saja Education Services
                <img src={arrow} alt='' />
              </p>
            </div>
          </div>
          <div>
            <p>Emerging provider of education solutions.</p>
            <p><span></span>Explore services</p>
          </div>
        </div>
        <div className={styles['hero-content-section-three']}>
          <p>Envisioning trusted educational chains across Egypt, the GCC, and MENA.</p>
          <p>Read More<span></span></p>
        </div>
      </div>
    </section>
  )
}
