import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import logo from '../../assets/images/Saja_Logo black.svg';
import menu from '../../assets/icons/menu.svg';
import close from '../../assets/icons/close.svg';

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const [activeItem, setActiveItem] = useState('/');
  const navigate = useNavigate();
  
  const toggleMenu = () => {
    setOpenMenu(prev => !prev);
  }

  const handleItemClick = (path) => {
    setActiveItem(path);
    navigate(path);
    setOpenMenu(false);
  }

  return (
    <nav className={styles['navbar']}>
      <img src={logo} className={styles['navbar-logo']} alt='Logo' onClick={() => handleItemClick('/')}/>
      <ul className={`${styles['navbar-menu']} ${openMenu ? styles['open'] : styles['close']}`}>
        <img src={close} onClick={toggleMenu} className={styles['close-menu']} alt='Close Menu' />
        <li
          className={`${styles['navbar-menu-item']} ${activeItem === '/about' ? styles['active'] : ''}`}
          onClick={() => handleItemClick('/about')}
        >
          about
        </li>
        <li
          className={`${styles['navbar-menu-item']} ${activeItem === '/why-saja' ? styles['active'] : ''}`}
          onClick={() => handleItemClick('/why-saja')}
        >
          why saja?
        </li>
        <li
          className={`${styles['navbar-menu-item']} ${activeItem === '/our-brands' ? styles['active'] : ''}`}
          onClick={() => handleItemClick('/our-brands')}
        >
          our brands
        </li>
        <li
          className={`${styles['navbar-menu-item']} ${activeItem === '/our-services' ? styles['active'] : ''}`}
          onClick={() => handleItemClick('/our-services')}
        >
          our services
        </li>
        <li
          className={`${styles['navbar-menu-item']} ${activeItem === '/news' ? styles['active'] : ''}`}
          onClick={() => handleItemClick('/news')}
        >
          news
        </li>
        <li
          className={`${styles['navbar-menu-item']} ${activeItem === '/affiliated-company' ? styles['active'] : ''}`}
          onClick={() => handleItemClick('/affiliated-company')}
        >
          Affiliated companies
        </li>
        <button className={styles['navbar-menu-item-action']}>contact us</button>
      </ul>
      <button className={styles['navbar-action']}>contact us</button>
      <img src={menu} onClick={toggleMenu} className={styles['navbar-menu-action']} alt='Open Menu' />
    </nav>
  )
}
