import React from "react";
import Hero from "../../components/brands/Hero";
import Company from "../../components/affiliatedCompanies/Company";
import AboutSaja from "../../components/aboutSaja";
import AboutCards from "../../components/aboutCards";
import aboutHero from "./../../assets/images/about-hero-img.jpg";
import ReachUs from '../../components/reachUs'



export default function AffiliatedCompany() {

  const heroData = {
    subHeading: "About",
    heading: "Empowering Learning Through Educational Brands",
    description: `Innovative platforms dedicated to enhancing learning and development across various educational fields .`,
    sectionImg: aboutHero
  };

  const aboutSaja = {
    subHeading: "About saja",
    heading: "Saja Education Services is more than just an educator",
    description: `We are the architects of tomorrow's leaders. From the first steps in preschool to the confident strides of high school, Saja nurtures young minds across all age groups, crafting educational journeys that resonate with each learner’s unique potential. Rooted in a passion for learning, Saja is committed to creating vibrant educational environments across Egypt, the GCC, and MENA, where knowledge thrives`,
  };


  return (
    <>
      <Hero data={heroData} />
      <AboutSaja data={aboutSaja}/>
      <AboutCards />
      <ReachUs />
    </>
  );
}
