import React from "react";
import Hero from "../../components/brands/Hero";
import Company from "../../components/affiliatedCompanies/Company";
import hero from "./../../assets/images/AffiliatedCompanies.svg";

import comapny1 from "./../../assets/images/company-1.jpg";
import comapny2 from "./../../assets/images/company-2.png";
import comapny3 from "./../../assets/images/company-3.jpg";

import logoCompany1 from "./../../assets/images/Innovera.png";
import logoCompany2 from "./../../assets/images/company-2.svg";
import logoCompany3 from "./../../assets/images/company-3.png";


export default function AffiliatedCompany() {
  const heroData = {
    subHeading: "Affiliated companies",
    heading: "Fostering holistic development in young children",
    description: `Innovative platforms dedicated to enhancing learning and development across various educational fields .`,
    sectionImg: hero,
    
  };

  const companiesData = [
    {
      imgSrc: comapny1,
      logoSrc: logoCompany1,
      description:
        "Innovera is a multi-national EdTech company founded in April 2013. We have grown organically by creating EdTech solutions that help address clients’ specific needs in their educational journey",
        direction: "left",
        link:"https://innovera.me/"
    },
    {
      imgSrc: comapny3,
      logoSrc: logoCompany3,
      description:
      "Appenza Company is a leading company in software development located in UK and Egypt with diversity",
        direction: "right",
        link: "http://appenza-studio.com/"
    },
    {
      imgSrc: comapny2,
      logoSrc: logoCompany2,
     
        description:
        "NEOWELL was created to introduce a unique wellness concept and innovative physical education experience and class structure for underserved kids and youth.",
        direction: "left",
        link: "https://neo-well.com/"
        
       
    },
  ];

  return (
    <>
      <Hero data={heroData} />

      {companiesData.map((item, index) => (
        <Company
          key={index}
          description={item.description}
          imgSrc={item.imgSrc}
          logoSrc={item.logoSrc}
          direction={item.direction}
          link={item.link}
        />
      ))}
    </>
  );
}
