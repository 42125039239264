import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './pages/landing';
import Brands from "./pages/brands"
import About from "./pages/about"
import AffiliatedCompany from "./pages/affiliatedCompany"
import Layout from './layout';
import { Helmet } from 'react-helmet';

function App() {
	return (
		<>
			<Helmet>
				<title>SAJA | Education Services</title>
			</Helmet>
			<BrowserRouter>
				<Layout>
					<Routes>
						<Route path='/' element={<Landing />} />
						<Route path='/our-brands' element={<Brands />} />
						<Route path='/affiliated-company' element={<AffiliatedCompany />} />
						<Route path='/about' element={<About />} />
					</Routes>
				</Layout>
			</BrowserRouter>
		</>
	);
}

export default App;
